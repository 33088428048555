<template>
    <LoadingView v-if="loading" />
    <article v-if="!loading" class="bg-white shadow sm:rounded-lg">

        <nav class="flex flex-none gap-4 justify-between items-center order-1 lg:order-2 rounded-t-lg bg-blue-200">
            <div class="py-2 px-5 flex-none">
                <FormButton 
                    class="shadow-none opacity-80 hover:opacity-100 transition-opacity delay-75 bg-white" 
                    icon="fa-chevron-left" :to="{ name: 'survey-list' }">
                    Return to List
                </FormButton>
            </div>
        </nav>

        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-start gnull-8">
           <div class="flex flex-col lg:flex-row gap-5 sm:gap-6 flex-1">
                <div class="flex flex-col gap-5 sm:gap-6 flex-1 justify-between">
                    <div class="flex flex-col lg:flex-row gap-5 justify-between flex-none">

                        <div class="flex-1 flex flex-col gap-2 lg:gap-0 order-2 lg:order-1">
                            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                                {{ survey.filename }}
                            </h1>
                            <dl class="lg:flex gap-2">
                                <dt class="sr-only">
                                    Division
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 pr-2">
                                    {{ survey.division.meta?.info?.name }}
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <section class="flex flex-row flex-wrap border-t border-l border-oakwood-gray-200 rounded grid grid-cols-1 lg:grid-cols-4">
                        <h2 class="sr-only col-span-full">Basic Information</h2>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center"
                                :class="[survey.parcels.length > 0 ? 'bg-oakwood-green-700' : 'bg-oakwood-red-500']">
                                <span class="far fa-draw-polygon"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Total Parcel Records</dt>
                                <dd class="font-semibold">{{ survey.parcels.length }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-ruler"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Measurement Unit</dt>
                                <dd class="font-semibold">{{ getUnit }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-landmark-flag"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Filing</dt>
                                <dd class="font-semibold">{{ survey.meta?.filing }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-sign-hanging"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Coordinate System</dt>
                                <dd class="font-semibold">{{ getSystem }}</dd>
                            </dl>
                        </aside>
                    </section>

                </div>
                <div class="w-full lg:w-1/5 flex">
                    <div class="text-5xl bg-oakwood-blue-300 text-white p-8 flex items-center justify-center w-full">
                        <span class="far fa-file"></span>
                    </div>
                </div>

            </div>
        </header>

        <div>
            <div class="block border-b border-oakwood-gray-200">
                <nav class="flex -mb-px px-6 gap-8" role="tablist">
                    <label role="tab" :aria-controls="`panel-${tab.name}`" v-for="tab, i in tabs" :key="`panel-${i}`"
                        :class="selectedTab?.name === tab.name ? 'border-oakwood-blue-500 border-b-2 text-oakwood-blue-600 focus:text-oakwood-blue-800 focus:border-oakwood-blue-700' : 'text-oakwood-gray-500 hover:text-oakwood-gray-700 border-transparent hover:border-oakwood-gray-300 focus:text-oakwood-gray-700 focus:border-oakwood-gray-300'"
                        class="cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none">
                        <input v-model="selectedTab" name="tab" type="radio" :value="tab" class="sr-only">
                        <span class="far mr-2 text-lg" :class="tab.icon"></span>
                        <span class="hidden sm:inline">{{ tab.title }}</span>
                    </label>
                </nav>
            </div>
            <section :id="`panel-${selectedTab.name}`" role="tabpanel" class="py-8 px-5 sm:px-6">     
                <ParcelsPane v-if="selectedTab.name === 'parcels'" :jobs="job" :parcels="survey.parcels"></ParcelsPane>
                <template v-if="selectedTab.name === 'debugger'">
                    <h2>Raw Data</h2>
                    <pre class="mt-8 w-full overflow-x-scroll p-6 bg-oakwood-gray-50 text-oakwood-red-700 shadow-inner shadow-oakwood-gray-200">{{ xml }}</pre>
                </template>
            </section>
        </div>

    </article>
</template>

<script>
import axios from 'axios';
import LoadingView from '@/components/LoadingView.vue';
import { FormButton } from '@/components/form';
import { systems, measurements } from '@/helpers/globals';
import ParcelsPane from '@/components/surveys/ParcelsPane.vue';

let tabs = [
    {
        name: 'parcels',
        icon: 'fa-draw-polygon',
        title: 'Parcels'
    },
    {
        name: 'debugger',
        icon: 'fa-bug-slash',
        title: 'Debugger'
    },
];

export default {
    data() {
        return {
            loading: true,
            survey: {},
            systems,
            measurements,
            tabs,
            selectedTab: tabs[0],
            xml: null,
            job: false
        }
    },
    methods: {
        show() {
            axios.get(`surveys/${this.$route.params?.id}`)
                .then(resp => {
                    this.survey = resp.data;
                    this.loading = false;
                    this.getXMLFile();
                    if(this.survey.parcels.length == 0) {
                        this.checkJobs();
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getXMLFile() {
            axios.get(`surveys/${this.$route.params?.id}/file`)
                .then(resp => {
                    this.xml = resp.data
                })
        },
        checkJobs() {
            let check = setInterval(
                () => {
                    axios.get(`surveys/${this.$route.params?.id}/jobs`)
                    .then(resp => {
                        if(resp.data.length == 0) {
                            clearInterval(check);
                            this.job = false;
                            this.show();
                        } else {
                            this.job = true;
                        }
                    })
                },
                5000
            );
        }
    },
    computed: {
        getSystem() {
            return systems.find(system => system.value == this.survey.meta?.system)?.label || '';
        },
        getUnit() {
            return measurements.find(unit => unit.value == this.survey.meta?.unit)?.label || '';
        }
    },
    created() {
        this.show();
    },
    components: {
        LoadingView, FormButton, ParcelsPane
    }

}
</script>../@/components/LoadingView.vue../@/components/form