<template>
    <div class="flex flex-col mt-8">
        <nav v-if="data.length > limit" class="text-oakwood-gray-700 px-4 pb-3 flex items-center justify-between sm:px-6">
            <div class="hidden sm:block">
                <p v-if="data.length > 0" class="text-sm leading-5 text-gray-700">
                    Showing
                    <span class="font-medium">{{ from + 1 }}</span>
                    to
                    <span class="font-medium">{{ to }}</span>
                    of
                    <span class="font-medium">{{ data.length }}</span>
                    results.
                </p>
                <p v-else class="text-sm leading-5 text-gray-700">
                    No results found.
                </p>
            </div>
            <div class="flex-1 flex justify-between sm:justify-end">
                <button :disabled="page == 1" @click="setPage('prev')" type="button"
                    :class="{ 'opacity-50 cursor-not-allowed': page == 1 }"
                    class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    Previous
                </button>
                <button :disabled="!max || page == max" @click="setPage('next')" type="button"
                    :class="{ 'opacity-50 cursor-not-allowed': !max || page == max }"
                    class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    Next
                </button>
            </div>
        </nav>
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-oakwood-gray-200 sm:rounded-lg">
                    <table v-if="paginated.length" class="min-w-full divide-y divide-oakwood-gray-200">
                        <thead>
                            <tr>
                                <slot name="headers"></slot>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-oakwood-gray-200">
                            <tr v-for="item, i in paginated" :key="`item-${i}`"
                                class="hover:bg-oakwood-blue-50 hover:bg-opacity-30">
                                <slot name="columns" :row="item" :i="i"></slot>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else class="py-4 px-5 sm:px-6">
                        There are currently no items.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page: 1
        }
    },
    methods: {
        setPage(direction) {
            if (direction == 'next') {
                this.page++;
            } else {
                this.page--;
            }
        }
    },
    computed: {
        paginated() {
            return this.data.slice(this.from, this.to);
        },
        from() {
            return (this.page * this.limit) - this.limit;
        },
        to() {
            let to = this.page * this.limit;
            return to <= this.data.length ? to : this.data.length;
        },
        max() {
            return Math.ceil(this.data.length / this.limit);
        },
    },
    props: {
        data: {
            type: Array,
            required: true,
            default: () => []
        },
        limit: {
            type: Number,
            default: 20
        }
    }
}
</script>
