<template>
    <LoadingView v-if="loading" />
    <article v-if="!loading" class="bg-white shadow sm:rounded-lg">

        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-center justify-between gap-8 sticky top-0 bg-white">
            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                {{ id ? 'Edit Sales Team Member' : 'Add Sales Team Member'}}
            </h1>
            <nav class="flex flex-none gap-4 justify-end agents-center order-1 lg:order-2">
                <div class="flex-none">
                    <FormButton 
                        class="bg-oakwood-gray-50 hover:opacity-100 transition-opacity delay-75" 
                        icon="fa-cancel" @click="$router.go(-1)">
                        Cancel
                    </FormButton>
                </div>
                <div>
                    <FormButton @click="update" :disabled="!valid" icon="fa-check" class="bg-oakwood-blue-500 text-white">
                        Save
                    </FormButton>
                </div>
                <div class="flex items-center gap-2">
                    <ToggleButton :id="`item-${agent.id}-status`" @changed="status()" :checked="agent.status == 'active'" 
                      :label="agent.status == 'active' ? 'Active' : 'Inactive'"/>
                      <label class="text-sm font-medium leading-5 text-oakwood-gray-700" :for="`item-${agent.id}-status`">{{ statuses.find(status => status.value == agent.status).label }}</label>
                </div>
            </nav>
        </header>


        <form autocomplete="off" class="grid grid-cols-1 p-5 gap-5 max-w-4xl">
            <input autocomplete="false" name="hidden" type="text" style="display:none;">
            <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                <p class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                    Photo
                </p>
                <UploadFiles v-model="photo_url" :multiple="false" class="col-span-2" />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                <label for="first_name" class="block text-sm font-medium leading-6 text-oakwood-gray-900">
                    First Name <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                </label>
                <input type="text" placeholder="First Name" id="first_name" v-model="agent.first_name" class="md:col-span-3 p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                <label for="last_name" class="block text-sm font-medium leading-6 text-oakwood-gray-900">
                    Last Name <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                </label>
                <input type="text" placeholder="Last Name" id="last_name" v-model="agent.last_name" class="md:col-span-3 p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                <label for="email" class="block text-sm font-medium leading-6 text-oakwood-gray-900">
                    Email
                </label>
                <input type="text" placeholder="Email" id="email" v-model="agent.email" class="md:col-span-3 p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                <label for="phone" class="block text-sm font-medium leading-6 text-oakwood-gray-900">
                    Phone <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                </label>
                <PhoneNumber id="phone" v-model="agent.meta.phone" class="text-sm" />
            </div>
        </form>
        
    </article>
</template>

<script>
import axios from 'axios';
import LoadingView from '@/components/LoadingView.vue';
import { FormButton, UploadFiles, ToggleButton, PhoneNumber } from '@/components/form';
import Agent from '@/models/agent';

export default {
    data() {
        return {
            id: null,
            loading: true,
            agent: new Agent,
            statuses: [
                {
                    label : 'Active',
                    value : 'active'
                },
                {
                    label : 'Inactive',
                    value : 'inactive'
                }
            ],
        }
    },
    methods: {
        show() {
            if(!this.id) return;
            axios.get(`agents/${this.id}`)
                .then(resp => {
                    this.agent = new Agent(resp.data);
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        update() {
            if(!this.id) {
                axios.postForm('agents', this.agent)
                    .then(() => {
                        this.$router.go(-1);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                axios.postForm(`agents/${this.id}?_method=PATCH`, this.agent)
                    .then(() => {
                        this.$router.go(-1);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        },
        status() {
            if(this.agent.status == 'active') {
                axios.delete(`agents/${this.agent.id}`)
                    .then(() => {
                        this.agent.status = 'inactive';
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                axios.patch(`agents/${this.agent.id}/restore`)
                    .then(() => {
                        this.agent.status = 'active';
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        },
        url(image) {
            return `${import.meta.env.VITE_APP_ENDPOINT}${image}`;
        }
    },
    computed: {
        photo_url: {
            get() {
                if(this.agent.photo_url) {
                    return [this.agent.photo_url]
                } else {
                    return this.agent.file == null ? [] : [this.agent.file]
                }
            },
            set(value) {
                this.agent.photo_url = null;
                this.agent.photo = null;
                this.agent.file = value[0];
            }
        },
        valid() {
            return this.agent.first_name && 
                this.agent.last_name && 
                this.agent.meta.phone;
        }
    },
    created() {
        if(this.$route.name == 'agent-edit') this.id = this.$route.params.id;
        if(this.id) {
            this.show();
        } else {
            this.loading = false;
        }
    },
    components: {
        LoadingView, FormButton, UploadFiles, ToggleButton, PhoneNumber
    }

}
</script>