<template>
    <LoadingView v-if="loading" />
    <article v-if="!loading" class="bg-white shadow sm:rounded-lg">

        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-center justify-between gap-8">
            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                Edit Image
            </h1>
            <nav class="flex flex-none gap-4 justify-end items-center order-1 lg:order-2">
                <div class="flex-none">
                    <FormButton 
                        class="bg-oakwood-gray-50 hover:opacity-100 transition-opacity delay-75" 
                        icon="fa-cancel" @click="$router.go(-1)">
                        Cancel
                    </FormButton>
                </div>
                <div>
                    <FormButton @click="update" :disabled="!valid" icon="fa-check" class="bg-oakwood-blue-500 text-white">
                        Save
                    </FormButton>
                </div>
            </nav>
        </header>


        <div class="grid grid-cols-1 md:grid-cols-3 p-5 gap-5">
            <div class="md:col-span-1">
                <figure class="w-full bg-oakwood-gray-200 lg:aspect-none group-hover:opacity-75">
                    <img
                        :src="item.url"
                        class="h-full w-full object-cover object-center"
                        :alt="item.caption" :title="item.alt" />
                </figure>
            </div>
            <div class="md:col-span-2 flex flex-col gap-5">
                <div class="grid grid-cols-1 md:grid-cols-2 pt-5">
                    <label :for="`alt_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="A simple identifier for this image">
                        Public Title <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <input type="text" placeholder="Like 'Kitchen' or 'Elevation A'" :id="`alt_${i}`" v-model="item.alt" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
                </div>
                <div class="border-t border-t-oakwood-gray-500 grid grid-cols-1 md:grid-cols-2 pt-5">
                    <label :for="`alt_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="A short description of what the image shows">
                        Public Caption <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <textarea :id="`alt_${i}`" placeholder="Like 'A view of the kitchen showing the eating area'" v-model="item.caption" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6"></textarea>
                </div>
                <div class="border-t border-t-oakwood-gray-500 grid grid-cols-1 md:grid-cols-2 pt-5">
                    <label :for="`alt_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="Search values that will help find this image">
                        Keywords
                    </label>
                    <textarea :id="`alt_${i}`" placeholder="Other special search words that are not public-facing" v-model="item.meta.keywords" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6"></textarea>
                </div>

                <div class="border-t border-t-oakwood-gray-500 grid grid-cols-1 md:grid-cols-2 pt-5">
                    <label :for="`position_${i}`" class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        View <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <select id="position" v-model="item.meta.position"
                                class="block p-2 bg-white border border-oakwood-gray-300 rounded w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                <option :value="null">Please Select...</option>
                                <option v-for="position, k in positions" :key="`position-${k}`" :value="position">{{
                                    position }}</option>
                            </select>
                        </div>
                    </div>
                </div>



            </div>
        </div>
        
    </article>
</template>

<script>
import axios from 'axios';
import LoadingView from '@/components/LoadingView.vue';
import { FormButton } from '@/components/form';
import {positions} from '@/helpers/globals';
import Attachment from '@/models/attachment';

export default {
    data() {
        return {
            id: null,
            item: {...(new Attachment)},
            loading: true,
            positions
        }
    },
    methods: {
        show() {
            if(!this.id) return;
            axios
                .get(`attachments/${this.id}`)
                .then(res => {
                    this.item = res.data;
                    if(res.data.meta === null)
                        this.item.meta = {
                            ...(new Attachment).meta
                        }
                    else {
                        res.data.meta = {
                            ...(new Attachment).meta,
                            ...res.data.meta
                        }
                    }
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        update() {
            axios
                .patch(`attachments/${this.id}`, this.item)
                .then(() => {
                    this.$router.go(-1);
                })
                .catch(err => {
                    console.error(err);
                })
        }
    },
    computed: {
        valid() {
            if(this.item.alt == '' || this.item.caption == '') return false;
            if(this.item.meta.position === null) return false;
            return true;
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.show();
    },
    components: {
        LoadingView, FormButton
    }
}
</script>../@/components/LoadingView.vue../@/components/form