<template>
    <ModalDialog :open="open" @close="close">
        <template #header>
            <h2 class="text-lg leading-6 font-medium text-oakwood-blue-700" id="modal-headline">
                Upload Images
            </h2>
        </template>
        <template #body>
            <div class="mt-6">
                <h3 class="text-sm leading-5 font-medium text-oakwood-gray-700">
                    Photos
                </h3>
                <div class="mt-2 flex flex-col gap-2 items-center">
                    <ul class="grid gap-2">
                        <li v-for="item,i in attachments"
                            :key="`selection-${i}`"
                            class=" rounded-lg p-4 grid gap-4 relative overflow-hidden" :class="(item.id && 'bg-green-200') || ((overLimit(item) || item.message) && 'bg-oakwood-red-100') || 'bg-oakwood-gray-50'">
                            <p class="flex justify-end items-center gap-4 col-span-full">
                                <p v-if="item.id" class="flex-1 text-green">
                                    <span class="fa fa-check-circle"></span> File {{ item.file.name }} ({{ getSize(item.file.size) }}) <strong>saved successfully.</strong>
                                </p>
                                <p v-else-if="item.message" class="flex-1 text-oakwood-red">
                                    <span class="fa fa-warning"></span> <strong>{{ item.message }}</strong>
                                </p>
                                <span class="far fa-close cursor-pointer flex-0 relative z-20" @click="removeItem(i)"></span>
                            </p>
                            <form v-if="!item.id" @submit.prevent="save(i)" class="grid md:grid-cols-6 lg:grid-cols-8 gap-4" :class="item.saving && 'opacity-50'">
                                <LoadingView v-if="item.saving" :absolute="true" />
                                <figure class="md:col-span-2 group-hover:opacity-75 grid gap-2">
                                    <img
                                        :src="getImageURL(item)"
                                        class="w-full aspect-square lg:aspect-none overflow-hidden object-cover object-center"
                                        :alt="item.caption" :title="item.alt" />
                                    <figcaption class="text-oakwood-gray text-sm">
                                        <strong>File:</strong> {{ item.file.name }} ({{ getSize(item.file.size) }})
                                    </figcaption>
                                </figure>
                                
                                <div class="md:col-span-4 lg:col-span-6 grid md:grid-cols-4 lg:grid-cols-6 gap-4">
                                    <label :for="`alt_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="A simple identifier for this image">
                                        Public Title <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                                    </label>
                                    <p class="md:col-span-3 lg:col-span-5">
                                        <input type="text" placeholder="Like 'Kitchen' or 'Elevation A'" :id="`alt_${i}`" v-model="item.alt" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
                                    </p>

                                    <label :for="`caption_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="A short description of what the image shows">
                                        Public Caption <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                                    </label>

                                    <p class="md:col-span-3 lg:col-span-5">
                                        <textarea :id="`caption_${i}`" placeholder="Like 'A view of the kitchen showing the eating area'" v-model="item.caption" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6"></textarea>
                                    </p>

                                    <label :for="`keywords_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="Search values that will help find this image">
                                        Keywords
                                    </label>

                                    <p class="md:col-span-3 lg:col-span-5">
                                        <textarea :id="`keywords_${i}`" placeholder="Other special search words that are not public-facing" name="keywords" v-model="item.meta.keywords" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6"></textarea>
                                    </p>
                                    <label :for="`position_${i}`" class="block text-sm font-medium leading-5 text-oakwood-gray-900 sm:mt-px sm:pt-2">
                                        View <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                                    </label>
                                    <div class="md:col-span-3 lg:col-span-5">
                                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                            <select :id="`position_${i}`" name="position" v-model="item.meta.position"
                                                class="block p-2 bg-white border border-oakwood-gray-300 rounded w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                                <option :value="null">Please Select...</option>
                                                <option v-for="position, k in positions" :key="`position-${k}`" :value="position">{{
                                                    position }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-span-full flex justify-end gap-2">
                                        <FormButton @click="removeItem(i)" icon="fa-cancel" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300">Cancel</FormButton>
                                        <FormButton type="submit" icon="fa-arrow-up-from-bracket" class="bg-oakwood-blue-500 text-white hover:bg-oakwood-blue-600" :disabled="!validate(item)">Upload Image</FormButton>
                                    </div>
                                </div>
                            </form>
                        </li>
                        <li
                            @drop.prevent="drop"
                            class="p-8 border border-dashed border-oakwood-gray-100 text-oakwood-gray-600 w-full flex flex-col items-center justify-center text-center">
                            <span class="far fa-images text-6xl mb-8"></span>
                            <p><label for="upload" class="cursor-pointer underline text-oakwood-blue hover:text-oakwood-blue-600">Select images</label> or drop images here.</p>
                            <p class="text-oakwood-gray-400 text-sm">PNG, JPG, WEBP and SVG not to exceed {{single}}MB each.</p>
                            <input id="upload" @change="(e) => { upload(e.target.files); }" type="file" multiple="true" accept="image/png,image/jpeg,image/webp,image/svg+xml" class="sr-only">
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <template #buttons>
            <FormButton type="button" @click="close" icon="fa-cancel"
                class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300">
                Close
            </FormButton>
            <FormButton @click="saveAll()" :disabled="!valid" icon="fa-arrow-up-from-bracket"
                class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700">Upload All Images</FormButton>
        </template>
    </ModalDialog>
</template>

<script>
    import axios from "axios";
    import ModalDialog from "./ModalDialog.vue";
    import FormButton from "@/components/form/FormButton.vue";
    import {positions} from '@/helpers/globals.js';
    import Attachment from '@/models/attachment';
import LoadingView from "./LoadingView.vue";

    export default {
        emits: [
            'save',
            'close'
        ],
        data() {
            return {
                attachments: [],
                positions,
                single: 2 // MB
            };
        },
        methods: {
            overLimit(item) {
                if(item.file.size > (this.single * 1024 * 1024)) {
                    item.message = "Over image file size limit.";
                    return true;
                }
                return false;
            },
            getSize(size) {
                let suffix = 'B';

                if(size > (1024*1024)) {
                    size = size / 1024 / 1024;
                    suffix = ' MB';
                } else if(size > 1024) {
                    size = size / 1024;
                    suffix = ' KB';
                }
                return new Intl.NumberFormat('en-US').format(size) + suffix;
            },
            validate(item) {
                return item.alt != null
                    && item.caption != null
                    && !this.overLimit(item)
                    && item.meta.position != null;
            },
            getImageURL(item) {
                return URL.createObjectURL(item.file);
            },
            removeItem(i) {
                this.attachments.splice(i,1);
            },
            drop(data) {
                if(data.dataTransfer.files.length) {
                    this.upload(data.dataTransfer.files);
                }
            },
            upload(files) {
                if(files.length)
                    this.attachments = [
                        ...this.attachments,
                        ...Array.from(files).map(file => {
                            return {
                                ...(new Attachment()),
                                file,
                                type: 'URL'
                            }
                        })
                    ];
            },
            save(i) {
                let item = this.attachments[i];
                item.saving = true;
                if(this.attachments.length > 0)
                    axios.postForm(`attachments`, item)
                        .then(resp => {
                            // resp.data = resp.data.map((attachment,i) => {
                            //     return {
                            //         id: attachment.id,
                            //         meta: {
                            //             type: this.attachments[i].type
                            //         }
                            //     };
                            // })
                            this.attachments[i] = {
                                ...(new Attachment(resp.data)),
                                file: item.file
                            };
                        })
                        .catch(error => {
                            item.message = error.resp.data.message;
                        });
            },
            saveAll() {
                if(this.unsaved.length > 0)
                    this.unsaved.forEach((item,i) => {
                        this.save(i);
                    });
            },
            close() {
                this.$emit('save', this.saved);
                this.attachments = [];
                this.$emit('close');
            }
        },
        computed: {
            unsaved() {
                return this.attachments.filter(item => item.id === null);
            },
            saved() {
                return this.attachments.filter(item => item.id !== null);
            },
            size() {
                return this.attachments.reduce((prev,curr) => {
                    return prev + curr.file.size
                }, 0);
            },
            valid() {
                if (this.attachments.length == 0) return false;
                if (this.attachments.map(item => {
                    return this.validate(item);
                }).includes(false)) {
                    return false;
                }
                return true;
            }
        },
        props: {
            open: {
                default: false
            },
            context: {
                default: false
            }
        },
        components: {
            ModalDialog,
            FormButton,
            LoadingView
        },
        
    };
</script>
  