<template>
    <LoadingView v-if="isLoading" />
    <header class="flex justify-between items-center">
      <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700 mb-8">Sales Team Members</h1>
      <FormButton :to="{name: 'agent-add', }" icon="fa-add" class="bg-oakwood-blue-500 text-white">Add New Sales Team Member</FormButton>
    </header>

    <div class="flex flex-col">
      <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4">
        <div class="mb-4 flex-initial">
          <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
          <input v-model="query.search" placeholder="Search agents by keyword" id="search" name="search"
            type="text" autocomplete="off"
            class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-300 rounded-md h-8">
        </div>
        <div class="mb-4 flex-initial">
          <SelectMultipleField v-model="query.status" label="Published" :options="statuses" empty="All Statuses"/>
        </div>
        <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
          <FormButton @click="reset" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
        </div>
      </div>
  
      <PaginationTable v-if="agents" :data="filtered">
        <template #headers>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Name
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Email
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Phone
            </th>
            <th scope="col" class="px-6 py-3 bg-oakwood-gray-50 text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider text-right">
              Active
            </th>
        </template>
        <template #columns="{row}">            
            <td class="px-6 py-4 whitespace-no-wrap" :class="row.status != 'active' && 'opacity-50'">
                <component :is="row.status == 'active' ? 'router-link' : 'p'" :to="{name: 'agent-edit', params: {id: row.id}}" class="flex gap-2 items-center">
                  <span class="rounded-full w-10 h-10 border border-oakwood-gray-700 aspect-square overflow-hidden flex items-center justify-center bg-oakwood-blue-400 text-white">
                      <img v-if="row.photo_url" :src="row.photo_url" />
                      <span v-else class="fas fa-user"></span>
                  </span>
                  <span class="text-sm leading-5 font-medium text-oakwood-gray-900">
                      {{ row.first_name }} {{ row.last_name }}
                  </span>
                </component>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-blue-600 hover:text-oakwood-blue-700" :class="row.status != 'active' && 'opacity-50'">
                <a v-if="row?.email" :href="`mailto:${row.email}`">{{ row.email }}</a>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-blue-600 hover:text-oakwood-blue-700" :class="row.status != 'active' && 'opacity-50'">
                <a v-if="row?.meta?.phone" :href="`tel:${row.meta.phone}`">{{ row.meta.phone }}</a>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
              <ToggleButton :id="`item-${row.id}-status`" @changed="save(row)" :checked="row.status == 'active'" 
                      :label="row.status == 'active' ? 'Active' : 'Inactive'"/>
                      <label class="sr-only" :for="`item-${row.id}-status`">{{ statuses.find(status => status.value == row.status).label }}</label>

            </td>
        </template>
      </PaginationTable>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import LoadingView from '@/components/LoadingView.vue';
  import {ToggleButton, SelectMultipleField, FormButton} from '@/components/form';
  import PaginationTable from '@/components/PaginationTable.vue';
  import {clean} from '@/helpers/regex';

  const query = {
    search: null,
    status: ['active']
  }
  
  export default {
    data() {
      return {
        agents: [],
        page: 1,
        limit: 20,
        isLoading: true,
        statuses: [
          {
            label : 'Active',
            value : 'active'
          },
          {
            label : 'Inactive',
            value : 'inactive'
          }
        ],
        query: {...query}
      }
    },
    methods: {
      index() {
        axios.get(`agents`)
          .then(res => {
            this.agents = res.data;
            this.isLoading = false;
          })
          .catch(e => {
            throw e;
          });
      },
      save(item) {
        this.isLoading = true;
        if(item.status == 'active') {
          axios.delete(`agents/${item.id}`)
            .then(() => {
              this.index();
              this.isLoading = false;
            })
            .catch(err => {
              console.error(err);
            });
        } else {
          axios.patch(`agents/${item.id}/restore`)
            .then(() => {
              this.index();
              this.isLoading = false;
            })
            .catch(err => {
              console.error(err);
            });
        }
      },
      setPage(direction) {
        if(direction == 'next') {
          this.page++;
        } else {
          this.page--;
        }
      },
      reset() {
        this.agentstore.clear();
      }
    },
    computed: {
      filtered() {
        let results = this.agents;
        if (!this.query.status.includes(null)) {
          results = results.filter(item => this.query.status.includes(item.status));
        }

        if (this.query.search != null) {
          const regex = new RegExp(clean(this.query.search), 'i');
          results = results.filter(item => regex.exec(item.first_name) || 
            regex.exec(item.last_name) || regex.exec(item?.email) || regex.exec(clean(item?.meta?.phone))
            );
        } 
        return results;
      },
      paginated() {
        return this.filtered.slice(this.from, this.to);
      },
      from() {
        return (this.page * this.limit) - this.limit;
      },
      to() {
        let to = this.page * this.limit;
        return to <= this.filtered.length ? to : this.filtered.length;
      },
      max() {
        return Math.ceil(this.filtered.length / this.limit);
      },
      hasFilters() {
        return JSON.stringify(this.query) != JSON.stringify(query);
      }
    },
    created() {
      this.index();
    },
    components: { LoadingView, ToggleButton, SelectMultipleField, FormButton, PaginationTable },
  }
  </script>