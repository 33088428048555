<template>
    <main class="flex flex-col items-center justify-center bg-oakwood-blue h-screen gap-8">
        <div class="max-w-md flex flex-col items-center px-4 gap-2 text-oakwood-blue-300">
            <img class="w-full" src="@/assets/images/oakwood-homes-logo.png" alt="Oakwood Homes logo">
            <h1 class="text-xl">Enhanced Data Feed Manager</h1>
        </div>
        <form class="w-full max-w-md rounded-lg bg-white flex flex-col gap-4 p-6">
            <div v-if="error" class="bg-oakwood-red-50 text-oakwood-red-600 border border-oakwood-red-600 p-4">
                {{ error }}
            </div>
            <section class="flex flex-col gap-4">
                <h2 class="text-sm text-oakwood-gray-700 sr-only">
                    Login using a username and password
                </h2>
                <div class="grid grid-cols-4 items-center">
                    <label for="email" class="text-sm">Email</label>
                    <input class="col-span-3 bg-oakwood-gray-50 border border-oakwood-gray-300 px-2 py-1 rounded-sm" type="text" v-model="email" id="email">
                </div>
                <div class="grid grid-cols-4 items-center">
                    <label for="password" class="text-sm">Password</label>
                    <input class="col-span-3 bg-oakwood-gray-50 border border-oakwood-gray-300 px-2 py-1 rounded-sm" type="password" v-model="password" id="password">
                </div>
                <div>
                    <FormButton class="bg-oakwood-blue-500 w-full justify-center text-white" icon="fa-right-to-bracket" @click="login(email,password)">Direct Login</FormButton>
                </div>
            </section>

            <section class="flex flex-col gap-4">
                <h2 class="text-sm text-oakwood-gray-700 sr-only">
                    Login using single sign-on
                </h2>
                <div>
                    <FormButton :href="azure" class="bg-oakwood-blue-800 w-full justify-center text-white" icon="fa-brands fa-microsoft">Login with Microsoft 365</FormButton>
                </div>
            </section>
        </form>
    </main>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import { mapActions, mapState } from 'pinia';
import { FormButton } from '@/components/form';

export default {
    data() {
        return {
            email: null,
            password: null,
            azure: `${import.meta.env.VITE_APP_ENDPOINT}auth/azure/redirect`
        }
    },
    methods: {
        ...mapActions(useAuthStore, ['login'])
    },
    computed: {
        ...mapState(useAuthStore, ['error'])
    },
    components: {
        FormButton
    }
}
</script>@/components/form