<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Available Homes</h2>
    </div>

    <PaginationTable :data="filtered" class="flex flex-col mt-8">
        <template #headers>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Available Home
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Sales Status
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider lg:w-1/5">
                Status
            </th>
        </template>
        <template #columns="{row}">
            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name: 'available-homes-show', params: { id: row.id } }" class="px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600 block">
                    {{ `${row.kova?.Lot?.StreetAddress}, ${row.kova?.Lot?.City} ${row.kova?.Lot?.State} ${row.kova?.Lot?.ZipCode}` }}
                </router-link>
            </td>

            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name: 'available-homes-show', params: { id: row.id } }" class="px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600 block">
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class="!row.kova?.Status == 'Released' ? 'bg-oakwood-red-100 text-oakwood-red-800' : 'bg-oakwood-green-100 text-oakwood-green-800'">
                        {{ row.kova?.Status }}
                    </span>
                </router-link>
            </td>

            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900 flex items-center gap-2">
                <ToggleButton :id="`item-${row.id}-status`" @changed="save(row)" :checked="row.status == 'active'" 
                :label="row.status == 'active' ? 'Published' : 'Unpublished'"/>
                <label :for="`item-${row.id}-status`">{{ statuses.find(status => status.value == row.status).label }}</label>
            </td>
        </template>
    </PaginationTable>
       
</template>

<script>
import axios from 'axios';
import {ToggleButton} from '@/components/form';
import PaginationTable from '@/components/PaginationTable.vue';
import { statuses } from '@/helpers/globals';

export default {
    data() {
        return {
            statuses
        }
    },
    emits: [
        'update'
    ],
    props: {
        sales_orders: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        save(item) {
            item.status = item.status != 'active' ? 'active' : 'inactive';
            axios.patch(`sales_orders/${item.id}`, item)
                .then(res => {
                    if(res.data?.id) {
                        this.$emit('update',res.data);
                    }
                })
                .catch(e => {
                    throw e;
                });
        }
    },
    computed: {
        filtered() {
            return this.sales_orders;
        }
    },
    components: {
        ToggleButton,
        PaginationTable
    }
}
</script>@/components/form