<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Info</h2>
        <nav class="flex items-end gap-2">
            <!-- <FormButton v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil" :to="{name: 'market-homes-edit', params: {id: floorplan.id}}">
                Edit
            </FormButton> -->
            <FormButton @click="edit()" v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil">
                Edit
            </FormButton>
            <template v-else>
                <FormButton @click="cancel()" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300" icon="fa-cancel">
                    Cancel
                </FormButton>
                <FormButton @click="save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-save">
                    Save
                </FormButton>
            </template>
        </nav>

    </div>
    <p class="text-sm text-oakwood-gray bg-oakwood-gray-50 p-2 mt-4 rounded border border-oakwood-gray-300">
        <span class="far fa-circle-exclamation mr-2"></span>
        This data is inherited from Kova. Any changes made here will not update Kova, but will override those values.
    </p>
    <dl class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-if="!editing">
        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Name</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.name }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Marketing Headline</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.headline }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Property Description</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.description }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Price</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ formatToCurrency(floorplan.meta?.info?.price) }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Square Feet</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ new Intl.NumberFormat('en-US', {}).format(floorplan.meta?.info?.sqft) }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Bedrooms</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.bedrooms }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Full Bathrooms</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.bathrooms.full }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Half Bathrooms</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.bathrooms.half }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Garages</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.garages }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Plan Website</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4"><a class="text-oakwood-blue-500 hover:underline" :href="floorplan.meta.info.website" target="_blank" title="Visit this home's website">{{ floorplan.meta.info.website }}</a></dd>
    </dl>

    <fieldset class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-else>
        <legend class="sr-only">Custom Properties</legend>

        <label for="info.name" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Name</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded-lg focus:border-oakwood-blue-400" id="info.name" v-model="item.meta.info.name"/>
        </div>

        <label for="info.headline" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Marketing Headline</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <input placeholder="Example: 'Luxury amenities and a central media room'" type="text" class="border border-oakwood-gray-200 w-full p-2 rounded-lg focus:border-oakwood-blue-400" id="info.headline" v-model="item.meta.info.headline"/>
        </div>

        <label for="info.description" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Property Description</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <textarea class="border border-oakwood-gray-200 w-full p-2 rounded-lg focus:border-oakwood-blue-400 min-h-48" id="info.description" v-model="item.meta.info.description"></textarea>
        </div>

        <label for="info.price" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Price</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <NumberField max="9999999" id="info.price" v-model="item.meta.info.price" prefix="$" :showLabel="false" />
        </div>

        <label for="info.sqft" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Square Feet</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <NumberField max="9999" id="info.sqft" width="w-20" v-model="item.meta.info.sqft" :showLabel="false" />
        </div>

        <label for="info.bedrooms" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Bedrooms</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <NumberField max="10" id="info.bedrooms" width="w-12" v-model="item.meta.info.bedrooms" :showLabel="false" />
        </div>

        <label for="info.bathrooms.full" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Full Bathrooms</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <NumberField max="10" id="info.bathrooms.full" width="w-12" v-model="item.meta.info.bathrooms.full" :showLabel="false" />
        </div>

        <label for="info.bathrooms.half" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Half Bathrooms</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <NumberField max="10" id="info.bathrooms.half" width="w-12" v-model="item.meta.info.bathrooms.half" :showLabel="false" />
        </div>

        <label for="info.garages" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Garages</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <NumberField max="10" id="info.garages" width="w-12" v-model="item.meta.info.garages" :showLabel="false" />
        </div>

        <label for="info.website" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Plan Website</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <URLField id="info.website" v-model="item.meta.info.website" class="w-full" />
        </div>

    </fieldset>
       
</template>

<script>
import { formatToCurrency } from '@/helpers/currencyHelpers';
import {NumberField, FormButton, SelectField, URLField} from '@/components/form';
import {floorplan} from '@/helpers/globals';

export default {
    data() {
        return {
            item: {
                meta: {
                    info: {
                        name: '',
                        description: '',
                        status: 'N/S',
                        sqft: 0,
                        bedrooms: 0,
                        bathrooms: {
                            half: 0,
                            full: 0
                        },
                        garages: 0,
                        price: 0,
                        headline: '',
                        website: ''
                    }
                }
            },
            editing: false,
            statuses: floorplan.ModelStatus
        }
    },
    methods: {
        edit() {
            this.editing = true;
            this.item = JSON.parse(JSON.stringify(this.floorplan));
        },
        cancel() {
            this.editing = false;
        },
        save() {
            this.$emit('update', this.item);
            this.cancel();
        },
        formatToCurrency
    },
    emits: [
        'update'
    ],
    props: {
        floorplan: {
            type: Object,
            required: true
        }
    },
    components: {
        FormButton,
        NumberField,
        SelectField,
        URLField
    }
}
</script>@/components/form