<template>
    <LoadingView v-if="isLoading" />
    <div v-else class="bg-white shadow sm:rounded-lg">

        <nav 
            class="flex flex-none gap-4 justify-between items-center order-1 lg:order-2 rounded-t-lg" 
            :class="{
                'bg-blue-200' : community.status == 'new', 
                'bg-green-200' : community.status == 'active', 
                'bg-oakwood-gray-200' : community.status == 'inactive'
            }">
            <div class="px-5 flex-none">
                <FormButton 
                    class="shadow-none bg-white opacity-80 hover:opacity-100 transition-opacity delay-75" 
                    :class="{
                        'bg-blue-300': community.status == 'new',
                        'bg-green-300': community.status == 'active',
                        'bg-oakwood-gray-300': community.status == 'inactive'
                    }"
                    icon="fa-chevron-left" :to="{ name: 'community-list' }">
                    Return to List
                </FormButton>
            </div>
            <div class="py-4 px-5 sm:rounded-tr-lg flex justify-end">
                <ToggleButton 
                    id="publish-toggle" 
                    :colors="{
                        on: 'bg-green',
                        off: community.status == 'new' ? 'bg-blue' : 'bg-oakwood-gray-800',
                        text_on: 'text-green',
                        text_off: community.status == 'new' ? 'text-blue' : 'text-oakwood-gray-800'
                    }"
                    :checked="community.status == 'active'" 
                    @changed="changeStatus" label="Set Publishing Status" />
                <label for="publish-toggle"
                    :class="{
                        'bg-blue' : community.status == 'new', 
                        'bg-green' : community.status == 'active', 
                        'bg-oakwood-gray-800' : community.status == 'inactive'
                    }"
                    class="text-white px-2 inline-block text-xs leading-6 font-semibold rounded-full ml-2"
                    v-text="statusLabel"></label>
            </div>
        </nav>
        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-start gnull-8">
           <div class="flex flex-col lg:flex-row gap-5 sm:gap-6 flex-1">
                <div class="flex flex-col gap-5 sm:gap-6 flex-1 justify-between">
                    <div class="flex flex-col lg:flex-row gap-5 justify-between flex-none">

                        <div class="flex-1 flex flex-col gap-2 lg:gap-0 order-2 lg:order-1">
                            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                                {{ community.meta?.info?.name || community.kova?.WebGroupName }}
                            </h1>
                            <dl class="lg:flex gap-2">
                                <dt class="sr-only">
                                    Description
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ community.kova?.Description }}
                                </dd>
                                <dt class="mt-1 text-sm leading-5 text-oakwood-gray-900">
                                    Kova Business Unit ID:
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ community.bunit_rid }}
                                </dd>
                                <dt class="sr-only">
                                    Business Unit ID
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ community.kova?.BUnitID }}
                                </dd>
                                <dt class="sr-only">
                                    Business Unit FID
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900">
                                    {{ community.kova?.BUnitFID }}
                                </dd>
                                
                            </dl>
                        </div>
                    </div>
                    <section class="flex flex-row flex-wrap border-t border-l border-oakwood-gray-200 rounded grid grid-cols-1 lg:grid-cols-4">
                        <h2 class="sr-only col-span-full">Basic Information</h2>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center"
                                :class="[!['Closed'].includes(community.kova?.Status) ? 'bg-oakwood-green-700' : 'bg-oakwood-red-500']">
                                <span class="far"
                                    :class="[!['Closed'].includes(community.kova?.Status) ? 'fa-lightbulb-on' : 'fa-lightbulb-slash']"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Kova Status</dt>
                                <dd class="font-semibold">{{ community.kova?.Status }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-grid-round-2"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Collections</dt>
                                <dd class="font-semibold">{{ collections.length }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-house"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Floorplans</dt>
                                <dd class="font-semibold">{{ floorplans?.length }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-sign-hanging"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Available Homes</dt>
                                <dd class="font-semibold">{{ sales_orders?.length }}</dd>
                            </dl>
                        </aside>
                    </section>

                </div>
                <div class="w-full lg:w-1/5 flex">
                    <img v-if="community.image" class="object-cover"
                        :src="community.image" :alt="community.kova?.Name"
                        :title="community.kova?.Name">
                    <div v-else class="text-5xl bg-oakwood-blue-300 text-white p-8 flex items-center justify-center w-full">
                        <span class="far fa-house"></span>
                    </div>
                </div>

            </div>
        </header>
        <div>
            <div class="block border-b border-oakwood-gray-200">
                <nav class="flex -mb-px px-6 gap-8" role="tablist">
                    <label role="tab" :aria-controls="`panel-${tab.name}`" v-for="tab, i in tabs" :key="`panel-${i}`"
                        :class="selectedTab?.name === tab.name ? 'border-oakwood-blue-500 border-b-2 text-oakwood-blue-600 focus:text-oakwood-blue-800 focus:border-oakwood-blue-700' : 'text-oakwood-gray-500 hover:text-oakwood-gray-700 border-transparent hover:border-oakwood-gray-300 focus:text-oakwood-gray-700 focus:border-oakwood-gray-300'"
                        class="cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none">
                        <input v-model="selectedTab" name="tab" type="radio" :value="tab" class="sr-only">
                        <span class="far mr-2 text-lg" :class="tab.icon"></span>
                        <span class="hidden sm:inline">{{ tab.title }}</span>
                    </label>
                </nav>
            </div>
            <section :id="`panel-${selectedTab?.name}`" role="tabpanel" class="py-8 px-5 sm:px-6">
                <InfoPane :community="community" @update="prepare" v-if="selectedTab?.name === 'info'" />
                <OfficePane :community="community" @update="prepare" v-if="selectedTab?.name === 'office'" />  
                <CollectionsPane :collections="collections" v-if="selectedTab?.name === 'collections'" />  
                <FloorplanPane :floorplans="floorplans" v-if="selectedTab?.name === 'floorplans'" />  
                <SalesOrderPane :sales_orders="sales_orders" v-if="selectedTab?.name === 'homes'" />  
                <ImagePane :parent="community" context="division" @update="(image) => {community.image = image.url}" v-if="selectedTab?.name === 'photos'" />  
                <VideoPane :item="community" context="community" @update="prepare" v-if="selectedTab?.name === 'videos'" />
                <InteractivePane :item="community" context="community" @update="prepare" v-if="selectedTab?.name === 'interactive'" />
                <LotsPane context="divisions" :id="community.id" :collections="collections" v-if="selectedTab?.name === 'lots'"/>
    
                <template v-if="selectedTab?.name === 'debugger'">
                    <h2>Raw Data</h2>
                    <pre>
                        {{ community }}
                    </pre>
                </template>
            </section>
        </div>
    </div>


</template>

<script>
import axios from "axios";
import LoadingView from '@/components/LoadingView.vue';
import {FormButton, ToggleButton} from '@/components/form';
import {InfoPane, OfficePane, CollectionsPane} from '@/components/communities';
import {InteractivePane, ImagePane, VideoPane, FloorplanPane, SalesOrderPane, LotsPane} from '@/components/shared';
import { mapState } from "pinia";
import { useAuthStore } from "@/stores/auth";
import community from '@/models/community';

let tabs = [
    {
        name: 'info',
        icon: 'fa-circle-info',
        title: 'General Information'
    },
    {
        name: 'office',
        icon: 'fa-building-flag',
        title: 'Sales Office'
    },
    {
        name: 'collections',
        icon: 'fa-grid-round-2',
        title: 'Collections'
    },
    {
        name: 'floorplans',
        icon: 'fa-house',
        title: 'Floorplans'
    },
    {
        name: 'homes',
        icon: 'fa-sign-hanging',
        title: 'Available Homes'
    },
    {
        name: 'photos',
        icon: 'fa-images',
        title: 'Images'
    },
    {
        name: 'videos',
        icon: 'fa-video',
        title: 'Videos'
    },
    {
        name: 'interactive',
        icon: 'fa-panorama',
        title: 'Interactives'
    },
    {
        name: 'lots',
        icon: 'fa-grid',
        title: 'Lots'
    },
    {
        name: 'debugger',
        icon: 'fa-bug-slash',
        title: 'Debugger'
    },
];

export default {
    data() {
        
        return {
            community: {
                ...community,
                id: this.$route.params.id
            },
            collections: [],
            floorplans: [],
            sales_orders: [],
            isLoading: true,
            tabs: [],
            selectedTab: tabs[0]
        }
    },
    methods: {
        async show() {
            await axios.get(`divisions/${this.community.id}`)
                .then(res => {
                    if(res.data.meta === null) res.data.meta = {};
                    res.data.meta = {...this.community?.meta, ...res.data.meta};
                    this.community = res.data;
                    this.isLoading = false;
                })
                .catch(e => {
                    throw e;
                });
        },
        async indexCollections() {
            await axios.get(`divisions/${this.community.id}/collections`)
                .then(res => {
                    if(!res.data?.status)
                        this.collections = res.data;
                })
                .catch(e => {
                    console.error(e);
                })
        },
        async indexFloorplans() {
            await axios.get(`divisions/${this.community.id}/floorplans`)
                .then(res => {
                    if(!res.data?.status)
                        this.floorplans = res.data;
                })
                .catch(e => {
                    console.error(e);
                })
        },
        async indexSalesOrders() {
            await axios.get(`divisions/${this.community.id}/sales_orders`)
                .then(res => {
                    if(!res.data?.status)
                        this.sales_orders = res.data;
                })
                .catch(e => {
                    console.error(e);
                })
        },
        changeStatus(toggle) {
            this.community.status = toggle ? 'active' : 'inactive';
            this.save();
        },
        save() {
            this.isLoading = true;
            axios.patch(`divisions/${this.community.id}`, this.community)
                .then(res => {
                    console.log(res.data);
                    this.isLoading = false;
                })
                .catch(e => {
                    throw e;
                });
        },
        prepare(value) {
            console.log(value);
            this.community = value;
            this.save();
        }
    },
    computed: {
        ...mapState(useAuthStore, ['user']),
        lot() {
            let lotId = this.community.kova?.Lot?.LotID;
            if(!lotId) return '-';
            return parseInt(lotId.split('-').pop());
        },
        statusLabel() {
            switch(this.community.status) {
                case 'active' :
                    return 'Published';
                case 'inactive' :
                    return 'Unpublished';
                case 'new' : 
                default :
                    return 'New';
            }
        }
    },
    created() {
        this.show();
        this.indexCollections();
        this.indexFloorplans();
        this.indexSalesOrders();
        this.tabs = tabs.filter(tab => {
            if(this.user.level < 2 && tab.name == 'debugger') return false;
            return true;
        });
    },
    components: {
        LoadingView,
        ToggleButton,
        FormButton,
        InteractivePane,
        VideoPane,
        CollectionsPane,
        FloorplanPane,
        SalesOrderPane,
        ImagePane,
        InfoPane,
        OfficePane,
        LotsPane
    },
}
</script>